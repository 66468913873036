import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function CustomFieldList() {
    // Use toast
    const toast = useToast();

    const refUserListTable = ref(null);

    // Table Handlers
    const tableColumns = [
        { key: "select", sortable: false },
        { key: "label", sortable: true },
        { key: "actions" }
    ];
    const perPage = ref(10);
    const totalCustomFieldList = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref("");
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);
    const statusFilter = ref(null);
    const customFieldsMetaData = ref([]);

    const dataMeta = computed(() => {
        const localItemsCount = refUserListTable.value
            ? refUserListTable.value.localItems.length
            : 0;
        return {
            from:
                perPage.value * (currentPage.value - 1) +
                (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalCustomFieldList.value
        };
    });

    const refetchData = () => {
        refUserListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery, statusFilter], () => {
        refetchData();
    });

    const fetchCustomFieldList = (ctx, callback) => {
        store
            .dispatch("app-custom-fields/fetchCustomFields", {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                status: statusFilter.value
            })
            .then(response => {
                const customFields = response.data.data;
                const { total } = response.data.meta;

                callback(customFields);
                totalCustomFieldList.value = total;
                customFieldsMetaData.value = customFields;
            })
            .catch((err) => {
                console.log(err);
                // toast({
                //     component: ToastificationContent,
                //     props: {
                //         title: "Error fetching customFields",
                //         icon: "AlertTriangleIcon",
                //         variant: "danger"
                //     }
                // });

                this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `Error fetching customFields`,
                      icon:'AlertTriangleIcon',
                      variant : 'danger',
                      hideClose: true,
                      
                    },
                      },
                      {
                    timeout : 3000,
                    position : 'bottom-center',
                    toastClassName:"error-info",
                    hideProgressBar : true,
                      })

            });
    };

    return {
        fetchCustomFieldList,
        tableColumns,
        perPage,
        currentPage,
        totalCustomFieldList,
        customFieldsMetaData,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refUserListTable,
        refetchData,
        statusFilter
    };
}
