<template>
  <div>
   
    <b-form-group
      v-for="(field, index) in schema.fields"
      v-bind:key="index"
      id="input-group-1"
      :label="field.label"
      label-for="input-1"
      description="We'll never share your email with anyone else."
    >
      <b-form-input
        v-if="field.type === 'input'"
        id="input-1"
        :model="field.model"
        :type="field.inputType"
        :placeholder="field.placeholder"
        :name="field.model"
        >
      </b-form-input>

      <b-form-radio-group
        v-if="field.type === 'radio'"
        id="radio-group-1"
        :model="field.model"
        :options="field.options"
        :name="field.model"
      >
      </b-form-radio-group>

      <b-form-select
        v-if="field.type === 'select'"
        :model="field.model"
        :options="field.options"
        :name="field.model"
      >
      </b-form-select>
    </b-form-group>
     <pre>
    <!-- {{ schema }} -->
    {{ model }}
    </pre>
  </div>
</template>

<script>

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BFormCheckbox,
  BFormRadioGroup,
  BFormSelect,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BFormSelect,
  },
  name: "FormGenerator",
  props: {
    schema: Object,
    model: Object,
  },
  data() {
    return {
      username: "Initial value",
    };
  },
};
</script>
